import { capitalize, intersection } from 'lodash';
import moment from 'moment-timezone';

// Some util functions which might be helpful

export function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function replaceAll(str, find, replace) {
	return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

/**
 * Replaces all occurrences of :key by its corresponding value in a string. Capitalized :Key will be replaced by capitalized Value.
 *
 * @param {string} str string containing :key
 * @param {Object} replacements object of key: value pairs to replace
 */
export function interpolate(str, replacements) {
	for (const [key, value] of Object.entries(replacements)) {
		str = replaceAll(str, ':' + key, value);
		str = replaceAll(str, ':' + capitalize(key), capitalize(value));
	}
	return str;
}

export const secondsToTime = (seconds) => {
	function leading_zero(num) {
		return (num < 10 ? '0' : '') + num;
	}

	let minutes = Math.floor(seconds / 60);
	let hours = Math.floor(minutes / 60);
	minutes = minutes - hours * 60;
	seconds = seconds - hours * 60 * 60 - minutes * 60;
	return leading_zero(hours) + ':' + leading_zero(minutes) + ':' + leading_zero(seconds);
};

export const timeToSeconds = (time) => {
	let parts = time.split(':');
	if (parts.length === 3) {
		return parseInt(parts[0]) * 60 * 60 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
	}
	if (parts.length === 2) {
		return parseInt(parts[0]) * 60 + parseInt(parts[1]);
	}
	return parseInt(parts[0]);
};

export const currentTime = (milliSeconds = true) => {
	const time = moment.tz('Europe/Amsterdam').valueOf();
	return milliSeconds ? time : Math.floor(time / 1000);
};

export function pxToNumber(px) {
	if (typeof px === 'number') return px;
	if (typeof px === 'string' && px.endsWith('px')) return Number(px.substring(0, px.length - 2));
	throw `Not a px value: "${px}"`;
}

/**
 * Hack to allow overriding methods that are defined in superclass with `this.method = function() {}`.
 * The original method of the superclass will be available via `this._method()`.
 */
export function removeOverriddenMethods(obj) {
	intersection(Object.getOwnPropertyNames(obj.constructor.prototype), Object.getOwnPropertyNames(obj)).forEach((name) => {
		obj[`_${name}`] = obj[name]; // preserve original method of superclass
		delete obj[name];
	});
}

export function formatDate(date) {
	return new Date(date).toLocaleString('nl-NL', { day: '2-digit', month: '2-digit', year: 'numeric' });
}
