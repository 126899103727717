import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import * as Request from './request';

export default function Login() {
	const emailRef = useRef(null);
	const passwordRef = useRef(null);
	const [loginError, setLoginError] = useState('');
	const [forgot, setForgot] = useState(false);
	const [forgotError, setForgotError] = useState(false);
	const [forgetSuccess, setForgotSuccess] = useState(false);

	const login = async () => {
		setLoginError('');
		const data = {
			email: emailRef.current.value,
			password: passwordRef.current.value,
		};
		// check for valid email format with regex
		if (!data.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/gi) || data.password.length < 3) {
			setLoginError('Please fill in all fields');
			return;
		}
		Request.post({
			url_slug: 'login',
			data,
			onSuccess: (data) => {
				location.href = '/admin';
			},
			onError: (data) => {
				setLoginError(data.error);
			},
		});
	};

	const forgot_password = async () => {
		const data = {
			email: emailRef.current.value,
		};
		Request.post({
			url_slug: 'forgot_password',
			data,
			onSuccess: (data) => {
				console.log(data);
				setForgotError(false);
				setForgotSuccess(true);
			},
			onError: (data) => {
				setForgotError(true);
				setForgotSuccess(false);
			},
		});
	};

	return (
		<>
			<div className="la-login">
				<div className="la-login_la-image">
					<img></img>
				</div>
				<div className="la-login_blur"></div>
				<div className="la-login_header">
					<div className="welcome">{lang['login.welcome']}</div>
					<div className="name">Learning Adventures</div>
				</div>
				<div className="la-login_form">
					<div className="la-login_form_card">
						<div className="la-login_form_header">
							<img className="la-login_form_img"></img>
						</div>
						{!forgetSuccess && (
							<div className={loginError || forgotError !== '' ? 'la-login_form_input has-error' : 'la-login_form_input'}>
								<label className="form_label">{lang['login.email']}</label>
								<input type="email" className="form_field form_text-field" ref={emailRef}></input>
								{forgotError && <div className="error">{lang['users.password_reset_invalid_email_message']}</div>}
								{loginError && <div className="error">{loginError}</div>}
							</div>
						)}
						{!forgot ? (
							<>
								<div className={forgotError !== '' ? 'la-login_form_input has-error' : 'la-login_form_input'}>
									<label className="form_label">{lang['login.password']}</label>
									<input type="password" className="form_field form_text-field" ref={passwordRef}></input>
								</div>
								<div className="la-login_form_button">
									<button className="btn--orange" onClick={login}>
										{lang['login.login']}
									</button>
								</div>
								<div className="la-login_form_button">
									<button className="btn--grey" onClick={() => setForgot(true)}>
										{lang['login.forgot_password']}
									</button>
								</div>
							</>
						) : (
							<>
								{forgetSuccess ? (
									<div className="success">{lang['users.password_reset_email_send_message']}</div>
								) : (
									<>
										<div className="la-login_form_button">
											<button className="btn--orange" onClick={forgot_password}>
												{lang['users.password_reset_email_send']}
											</button>
										</div>{' '}
									</>
								)}
								<div className="la-login_form_button">
									<button
										className="btn--grey"
										onClick={() => {
											setForgot(false);
											setForgotError(false);
											setForgotSuccess(false);
										}}
									>
										{lang['login.back']}
									</button>
								</div>{' '}
							</>
						)}

						<div className="la-login_form_contact">
							<p className="la-login_form_questions">{lang['login.questions']}</p>
							<p>
								{lang['login.contact']}{' '}
								<a className="contact" href="mailto:info@learningadventures.nl">
									info@learningadventures.nl
								</a>
							</p>
							<p className="powered">
								{lang['login.powered']}
								<a href="https://www.learningadventures.nl"> Learning Adventures</a>
							</p>
						</div>
					</div>
				</div>
				<div className="la-login_name-bar"></div>
			</div>
		</>
	);
}

const main_root = ReactDOM.createRoot(document.getElementById('main_area'));
main_root.render(<Login />);
